.idForm {
    width: 100%;
    height: calc(100vh * 0.6);
    display: grid;
    place-items: center;

    > div {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    h2 {
        font-size: 2rem;
        font-weight: normal;
        margin-bottom: 0.5rem;
    }

    input {
        background: #222;
        font-size: 1.2rem;
        padding: 0.5rem 1rem;
        display: block;
        margin-bottom: 0.5rem;
    }

    select {
        background: #fff;
        font-size: 1.2rem;
        padding: 0.2rem 1rem;
        display: block;
        margin-bottom: 0.5rem;
        text-align: left;
        color: #000;
    }

    button {
        cursor: pointer;
        background-color: var(--color-theme-500);
        color: #fff;
        font-size: 1.2rem;
        border: 0;
        padding: 0.2rem 1rem;
    }
}

.resetContainer {
    position: absolute;
    top: 72px;
    right: 1rem;
    display: grid;
    place-items: center;
    z-index: 150;
    height: 80px;

    > div {
        display: flex;
        gap: 1rem;
    }

    button {
        cursor: pointer;
        background-color: var(--color-theme-500);
        color: #fff;
        font-size: 1.2rem;
        border: 0;
        padding: 0.2rem;
        height: 70px;
    }
}

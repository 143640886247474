.scores {
    position: absolute;
    top: 72px;
    left: 1rem;
    border: 1px solid white;
    z-index: 100;
    background-color: #222;
    width: 20rem;
    transition: all 0.5s;

    > div {
        height: 1.5rem;
        display: flex;

        span {
            height: 100%;
            display: grid;
            place-items: center;
            border: 1px solid white;
            font-size: 0.8rem;
        }

        .singleStar {
            display: inline;
            border: none;
        }
    }

    .stars {
        width: 4rem;
    }

    .username {
        width: 8rem;
    }

    .army {
        width: 4rem;
    }

    .land {
        width: 4rem;
    }
}

.hiding {
    left: -20rem !important;
}
